<template>
  <div>
    <h4>Happy Virus</h4>
    <div class="small mb-3">Video, Vial</div>
    <p class="small">
      本作品では、東京大学小澤研究室の協力のもと、人のニューロンに感染しセロトニンの受容体を発現するウイルスを実際に作った。セロトニンは精神安定や幸福感をもたらすことから幸せホルモンとも呼ばれる。本映像では、人々を幸せにしたいと考えた主人公が、このウイルスを作るストーリーが描かれる。<br/>バイオテクノロジーの発展により、ウイルスのデザインはこのように一部可能になってきた。現在、テクノロジーは新型コロナウイルスワクチンや遺伝子治療など、多くの点で人々の役に立つものとしての研究が進んでいる。しかし、テクノロジーが広がり身近になると悪用される可能性も高まる。もしかすると今後、今回のようなパンデミックが人為的に引き起こされることもあるかもしれない。この作品を通して次のことを問いたい：私たちはテクノロジーでどのような未来を作っていきたいのだろうか？
    </p>
    <p class="small">
      In this work, we collaborated with the University of Tokyo’s Ozawa Lab to produce a “Happy Virus” that infects human neurons and expresses serotonin receptors. Serotonin is often called the happy hormone because it brings about mental stability and a sense of well-being. In this film, the protagonist designs this virus in the hope of making the world a happier place (in her view). <br/>With the recent development of biotechnology, designing such a new virus in the lab has become more accessible. Biotechnology is used in many positive ways, such as rapidly developing the coronavirus vaccine or curing diseases with gene therapy. However, making the technology more accessible could also open up possibilities of misuse, such as someone intentionally designing a virus to create a deadly pandemic like COVID-19. In this work, we’d like to provoke a question: What kind of futures do we want to allow ourselves to create?
    </p>
    <div class="video">
      <iframe width="100%" src="https://www.youtube-nocookie.com/embed/NKv3P0EWfmA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <img src="@/assets/virus.jpg" class="img-fluid mt-3" />
    <img src="@/assets/virus1.jpg" class="img-fluid mt-3" />
  </div>
</template>

<style>
img {
  margin-bottom: 10px;
}
</style>
